@import '../../styles/main';

// Update root styles here as needed

html,
body,
#root,
.wavez-app {
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

body,
#root,
.wavez-app {
  flex: 1;
}


body {
  margin: 0;
}
