@import 'styles/main.scss';

.wavez-landing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $white;
  background-color: $brand-black;
  overflow: hidden;

  &__squiggle-container {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  &__squiggle {
    flex: 1;
  }

  &__header {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1800px;
    padding: 1rem;
    background-color: transparent;
    box-sizing: border-box;
    z-index: 1;
  }

  &__content-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 1800px;
    min-width: 1800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__content-section-main {
    background-image: url('/assets/backgrounds/desktop/Landing Background Image.png');
  }

  &__content-section-music {
    top: -1.5rem;
    background-image: url('/assets/backgrounds/desktop/Music Background Image.png');
  }

  &__content-section-lights {
    top: -3rem;
    background-image: url('/assets/backgrounds/desktop/Lights Background Image.png');
  }

  &__content-section-customize {
    top: -4.5rem;
    background-image: url('/assets/backgrounds/desktop/Customize Background Image.png');
  }

  &__section-title-container {
    position: relative;
    top: 0px;
    opacity: 1;
    transition: 0.5s ease-in-out;
    &-hidden {
      top: -20px;
      opacity: 0;
    }
  }

  &__section-title,
  &__section-title-feature {
    position: relative;
    width: 158px;
    margin-bottom: 0;
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__section-title-feature {
    margin-top: 0.5rem;
  }

  &__section-description {
    position: relative;
    max-width: 480px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: $black-opaque-50;
    &-music,
    &-customize {
      left: 9rem;
    }
    &-lights {
      right: 7rem;
      text-align: right;
    }
  }

  &__section-background {
    position: absolute;
    bottom: -10px;
    height: 30px;
    width: 158px;
    background-color: $black-opaque-50;
  }

  &__content-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__logo {
    width: 600px;
  }

  &__background-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    box-sizing: border-box;
    background-color: $black-opaque-50;
    z-index: 1;
    transition: 0.5s ease-in-out;
  }

  &__background-circle-main {
    position: absolute;
    bottom: -6rem;
    height: 288px;
    width: 288px;
    padding-top: 1rem;
    &-hidden {
      background-color: transparent;
    }
  }

  &__background-circle-feature {
    position: absolute;
    height: 350px;
    width: 350px;
    &-customize {
      top: calc(50% - 6rem);
      right: calc(50% - 14rem);
    }
    &-music {
      top: calc(50% - 3rem);
      right: calc(50% - 14rem);
    }
    &-lights {
      top: calc(50% - 6rem);
      left: calc(50% - 12rem);
    }
  }

  &__content-icon {
    height: 188px;
    width: 188px;
  }

  &__content-icon-customize {
    height: 124px;
    width: 124px;
  }

  &__content-icon-hue {
    width: 33px;
  }

  &__content-icon-lifx,
  &__content-icon-nanoleaf {
    width: 55px;
  }

  &__content-icon-soundcloud {
    width: 80px;
  }

  &__content-icon-spotify {
    width: 66px;
  }

  &__content-icon-container {
    position: relative;
    display: grid;
    align-items: center;
    gap: 2rem;
    &-customize {
      gap: 1.75rem;
    }
    &-customize,
    &-music {
      grid-template-columns: 1fr 1fr;
    }
    &-music,
    &-lights {
      gap: 2rem;
    }
    &-music {
      left: 7rem;
    }
    &-lights {
      right: 2rem;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__customize-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 8rem;
    width: 100%;
    background-image: url('/assets/backgrounds/desktop/Footer Background.png');
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }

  &__footer-background {
    position: absolute;
    bottom: 14px;
    height: 18px;
    width: 230px;
    background-color: $black-opaque-50;
  }

  &__footer-text {
    z-index: 1;
  }
}
