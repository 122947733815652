// Add color, box, measurement, etc. variables here

// BRAND COLORS
$brand-white: #dbfffb;
$brand-xx-light: #b3fff7;
$brand-x-light: #85fff3;
$brand-light: #57ffee;
$brand-medium: #29ffea;
$brand-color: #00ffe5;
$brand-hover: #00e6cf;
$brand-active: #00d1bc;
$brand-dark: #009e8e;
$brand-x-dark: #007065;
$brand-xx-dark: #00423c;
$brand-black: #000f0e;
$brand-focus-outline: #0bd5e0;

$brand-color-20: rgba(0, 255, 229, 0.2);
$brand-color-40: rgba(0, 255, 229, 0.4);
$brand-color-60: rgba(0, 255, 229, 0.6);
$brand-color-80: rgba(0, 255, 229, 0.8);

// ACCENT COLORS
$accent-customize: #662CCE;
$accent-lights: #f56c70;
$accent-music: #2519d0;

$accent-customize-20: rgba(102, 44, 206, 0.2);
$accent-customize-40: rgba(102, 44, 206, 0.4);
$accent-customize-60: rgba(102, 44, 206, 0.6);
$accent-customize-80: rgba(102, 44, 206, 0.8);
$accent-lights-20: rgba(245, 108, 112, 0.2);
$accent-lights-40: rgba(245, 108, 112, 0.4);
$accent-lights-60: rgba(245, 108, 112, 0.6);
$accent-lights-80: rgba(245, 108, 112, 0.8);
$accent-music-20: rgba(137, 25, 208, 0.2);
$accent-music-40: rgba(137, 25, 208, 0.4);
$accent-music-60: rgba(137, 25, 208, 0.6);
$accent-music-80: rgba(137, 25, 208, 0.8);

// BACKGROUND COLORS
$background-white: #004d47;
$background-xx-light: #00423e; // gray-02 => gray-03, gray-01
$background-x-light: #003330; // gray-04 => gray-05, gray-06
$background-light: #001f1d; // gray-08 => gray-07, gray-09
$background: #000f0e; // gray-11 => gray-10, gray-12
$background-dark: #000f0e; // gray-14 => gray-13, gray-15
$background-x-dark: #000f0e; // gray-17 => gray-16, gray-18
$background-xx-dark: #000f0e; // gray-20 => gray-19, gray-21
$background-black: #000;

// MISC COLORS
$black-opaque-50: rgba(4, 5, 16, 0.5);
$black-opaque-60: rgba(4, 5, 16, 0.6);
$black-opaque-70: rgba(4, 5, 16, 0.7);
