@import './variables';
@import './mixins';

// Imports all variables from @f-design
// Any duplicate names from 'variables' in this repo
//   will take precedent
@import '~@f-design/component-library/dist/scss/components/all.scss';

// Update root styles here as needed
body {
  margin: 0;
  // From @f-design
  color: $gray-19;
  font-size: 1rem;
  // Open Sans and Google Font icons are in index.html
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
